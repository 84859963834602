/// <reference path='../../../../../node_modules/@types/googlemaps/index.d.ts' />

import { Component, OnInit, AfterViewInit } from "@angular/core";
import { SwiperConfigurationsService } from "src/app/services/swiper-configurations.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-contato-page1",
  templateUrl: "./contato-page1.component.html",
  styleUrls: ["./contato-page1.component.scss"]
})
export class ContatoPage1Component implements OnInit, AfterViewInit {
  constructor(public swiperConfigsService: SwiperConfigurationsService, public translateService: TranslateService) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.InitMap();

    setTimeout(() => {
      let mapsContainer = document.getElementById("mapsContato") as HTMLElement;
      mapsContainer.addEventListener('dragstart', e => {
        console.log(e);
        this.swiperConfigsService.swiperPrincipal.getConfig().allowSlideNext = false;
        this.swiperConfigsService.swiperPrincipal.getConfig().allowSlidePrev = false;
      });
      mapsContainer.addEventListener('mouseup', e => {
        console.log(e);
        this.swiperConfigsService.swiperPrincipal.getConfig().allowSlideNext = true;
        this.swiperConfigsService.swiperPrincipal.getConfig().allowSlidePrev = true;
      });
    }, 600);
  }

  InitMap() {
    // -23.6211764,-46.6627196,21
    let loc = { lat: -23.6211764, lng: -46.6627196 };
    let map = new google.maps.Map(document.getElementById("mapsContato"), {
      zoom: 17,
      center: loc,
      draggable: true,
      mapTypeControl: false
    });
    let marker = new google.maps.Marker({
      position: loc,
      map: map,
      icon: "assets/Imagens/stuffs/icon-map.png",
      title:
        "llacuna - Rua Baronesa de Bela Vista, 411 - Conj 519 - Campo Belo • São Paulo - SP"
    });
  }
}
