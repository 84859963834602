import { Component, OnInit } from '@angular/core';
import { SwiperConfigurationsService } from 'src/app/services/swiper-configurations.service';

@Component({
  selector: 'app-intro-page3',
  templateUrl: './intro-page3.component.html',
  styleUrls: ['./intro-page3.component.scss']
})
export class IntroPage3Component implements OnInit {

  constructor(public swiperConfigService: SwiperConfigurationsService) { }

  ngOnInit() {
  }

}
