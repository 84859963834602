import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { VgCoreModule } from "videogular2/compiled/core";
import { VgControlsModule } from "videogular2/compiled/controls";
import { VgOverlayPlayModule } from "videogular2/compiled/overlay-play";
import { VgBufferingModule } from "videogular2/compiled/buffering";

import { AppComponent } from "./app.component";
import { SwiperModule } from "ngx-swiper-wrapper";
import { LoadingPageComponent } from "./pages/loading-page/loading-page.component";
import { MenuComponent } from "./components/menu/menu.component";
import { IntroPage1Component } from "./pages/1-intro-session/intro-page1/intro-page1.component";
import { IntroPage2Component } from "./pages/1-intro-session/intro-page2/intro-page2.component";
import { IntroPage3Component } from "./pages/1-intro-session/intro-page3/intro-page3.component";
import { IntroPage4Component } from "./pages/1-intro-session/intro-page4/intro-page4.component";
import { IntroPage5Component } from "./pages/1-intro-session/intro-page5/intro-page5.component";
import { LlacunaPage1Component } from "./pages/2-llacuna-session/llacuna-page1/llacuna-page1.component";
import { LlacunaPage2Component } from "./pages/2-llacuna-session/llacuna-page2/llacuna-page2.component";
import { CasesPage1Component } from "./pages/3-cases-session/cases-page1/cases-page1.component";
import { CasesPage2Component } from "./pages/3-cases-session/cases-page2/cases-page2.component";
import { ContatoPage1Component } from "./pages/4-contato-session/contato-page1/contato-page1.component";
import { ListaProjetosComponent } from "./components/lista-projetos/lista-projetos.component";
import { SwiperConfigurationsService } from "./services/swiper-configurations.service";
import { IntroSessionPageComponent } from "./pages/1-intro-session/intro-session-page.component";
import { llacunaSessionPageComponent } from "./pages/2-llacuna-session/llacuna-session-page.component";
import { CasesSessionPageComponent } from "./pages/3-cases-session/cases-session-page.component";
import { ContatoSessionPageComponent } from "./pages/4-contato-session/contato-session-page.component";
import { SwiperParallaxDirective } from "./directives/swiperParallax/swiper-parallax.directive";
import { LlacunaDesktopMenuComponent } from './components/llacuna-desktop-menu/llacuna-desktop-menu.component';
import { DataProviderService } from './services/data-provider.service';
import { PortfolioPanelComponent } from './components/portfolio-panel/portfolio-panel.component';
import { PortfolioService } from './services/portfolio.service';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService, TranslateStore } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonModule } from '@angular/common';
import { MensagemOrientacaoComponent } from './components/mensagem-orientacao/mensagem-orientacao.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoadingPageComponent,
    MenuComponent,
    IntroSessionPageComponent,
    IntroPage1Component,
    IntroPage2Component,
    IntroPage3Component,
    IntroPage4Component,
    IntroPage5Component,
    llacunaSessionPageComponent,
    LlacunaPage1Component,
    LlacunaPage2Component,
    CasesSessionPageComponent,
    CasesPage1Component,
    CasesPage2Component,
    ContatoSessionPageComponent,
    ContatoPage1Component,
    ListaProjetosComponent,
    SwiperParallaxDirective,
    LlacunaDesktopMenuComponent,
    PortfolioPanelComponent,
    MensagemOrientacaoComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    SwiperModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule
  ],
  providers: [SwiperConfigurationsService, DataProviderService, PortfolioService],
  bootstrap: [AppComponent]
})
export class AppModule { }
