<div
  class="texto-container texto-esquerda line-height"
  [appSwiperParallax]="swiperConfigService.paralaxNiveis.frente"
  [innerHTML]="'INTRO-SESSION.INTRO-PAGE3.TEXTO-ESQUERDA' | translate"
>
  
</div>
<div
  class="texto-container texto-direita text-right line-height"
  [appSwiperParallax]="swiperConfigService.paralaxNiveis.frente"
  [innerHTML]="'INTRO-SESSION.INTRO-PAGE3.TEXTO-DIREITA' | translate"
>

</div>

<div class="image-container d-flex d-center">
    <div class="image-lampada"></div>
    <div class="image-line1"></div>
    <div class="image-line2"></div>
    <div class="image-line5"></div>
    <div class="image-line3" [appSwiperParallax]="swiperConfigService.paralaxNiveis.atras"></div>
    <div class="image-line4" [appSwiperParallax]="swiperConfigService.paralaxNiveis.atras"></div>
    <div class="image-line" [appSwiperParallax]="swiperConfigService.paralaxNiveis.atras"></div>
</div>

<div class="image-cloud" [appSwiperParallax]="swiperConfigService.paralaxNiveis.frente"></div>
<div class="image-cloud-back" [appSwiperParallax]="swiperConfigService.paralaxNiveis.atras"></div>
<div class="image-cloud-back2" [appSwiperParallax]="swiperConfigService.paralaxNiveis.atras"></div>