<swiper
  [config]="swiperConfigsService.swiperPrincipalConfig"
  [index]="currentIndex"
  #swiperPrincipalObj
  [class]="'swiper-principal ' + orientationClass"
  (indexChange)="onIndexChange($event)"
>
  <div class="swiper-slide" data-hash="">
    <intro-session-page></intro-session-page>
  </div>
  <div class="swiper-slide" data-hash="llacuna [...]">
    <llacuna-session-page></llacuna-session-page>
  </div>
  <div class="swiper-slide" data-hash="cases">
    <cases-session-page (openPortfolioEventOutput)="openPortfolio($event)"></cases-session-page>
  </div>
  <div class="swiper-slide" data-hash="contato">
    <contato-session-page></contato-session-page>
  </div>
</swiper>

<llacuna-desktop-menu #llacunaDesktopMenu [hidden]="selectedPortfolio !== undefined"></llacuna-desktop-menu>
<app-portfolio-panel *ngIf="selectedPortfolio" [selectedPortfolio]="selectedPortfolio" (deselectPortfolioEvent)="deselectPortfolioEvent($event)"  ></app-portfolio-panel>

<div [hidden]="!loading" class="loading-container">
  <div class="loading-wrapper">
    <div class="loading-text">Melhorando sua experiência</div>
    <div class="loading-img"></div>
  </div>
</div>

<mensagem-orientacao></mensagem-orientacao>