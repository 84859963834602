import { Component, OnInit } from '@angular/core';
import { SwiperConfigurationsService } from 'src/app/services/swiper-configurations.service';

@Component({
  selector: 'app-intro-page5',
  templateUrl: './intro-page5.component.html',
  styleUrls: ['./intro-page5.component.scss']
})
export class IntroPage5Component implements OnInit {

  constructor(public swiperConfigService: SwiperConfigurationsService) { }

  ngOnInit() {
  }

}
