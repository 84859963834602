import { Component, OnInit } from '@angular/core';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { SwiperConfigurationsService } from 'src/app/services/swiper-configurations.service';

@Component({
  selector: 'app-cases-page1',
  templateUrl: './cases-page1.component.html',
  styleUrls: ['./cases-page1.component.scss']
})
export class CasesPage1Component implements OnInit {

  constructor(public dataProviderService: DataProviderService, public swiperConfigurationService: SwiperConfigurationsService) { }

  ngOnInit() {
  }

}
