<div class="pp-container" *ngIf="selectedPortfolio !== undefined">
    <div class="pp-bgimage"
        [style.backgroundImage]="'url(https://llacuna.ag/albuns/' + selectedPortfolio.backgroundFullImageUrl + ')'">
    </div>
    <div class="pp-bgimage-black"></div>
    <div class="pp-close-btn" (click)="deselectPortfolio()"></div>
    <div class="pp-wrapper">
        <div class="title">{{translateService.currentLang === 'pt' ? selectedPortfolio.title : selectedPortfolio.title_en}}</div>
        <div class="subtitle">{{translateService.currentLang === 'pt' ? selectedPortfolio.subtitle : selectedPortfolio.subtitle_en}}</div>
        <div class="swiper-container swiper-portfolio" [hidden]="currentAlbum.length === 0">
            <div class="swiper-wrapper">
                <div class="swiper-slide" *ngFor="let item of currentAlbum"><img [src]="item"
                        style="max-height: 500px; max-width: 100%; width: 100%;">
                </div>
            </div>
            <div class="swiper-pagination"></div>
        </div>
        <div *ngIf="selectedPortfolio.videosUrl">
            <div class="botao-video" *ngFor="let item of selectedPortfolio.videosUrl; let i = index" [hidden]="item.url === '' || item.url === undefined || item.url === null"
                (click)="assistirVideo(item)">Assistir vídeo {{selectedPortfolio.videosUrl.length <= 1 ? '' : i + 1}}</div>
        </div>
        <div class="description">
            {{translateService.currentLang === 'pt' ? selectedPortfolio.description : selectedPortfolio.description_en}}
        </div>
    </div>
</div>