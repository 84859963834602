import { Component, OnInit } from '@angular/core';
import { SwiperConfigurationsService } from 'src/app/services/swiper-configurations.service';

@Component({
    selector: 'contato-session-page',
    templateUrl: './contato-session-page.component.html',
    styleUrls: ['./contato-session-page.component.scss']
})
export class ContatoSessionPageComponent implements OnInit {
    constructor(public swiperConfigService: SwiperConfigurationsService) { }

    ngOnInit(): void { }
}
