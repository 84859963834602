import { Component, OnInit } from '@angular/core';
import { SwiperConfigurationsService } from 'src/app/services/swiper-configurations.service';

@Component({
  selector: 'llacuna-session-page',
  templateUrl: './llacuna-session-page.component.html',
  styleUrls: ['./llacuna-session-page.component.scss']
})
export class llacunaSessionPageComponent implements OnInit {
  constructor(public swiperConfigService: SwiperConfigurationsService) { }

  ngOnInit(): void { }

  showVideo = false;

  onSlideIndexChange(data) {
    switch (data) {
      case 1:
        this.showVideo = true;
        // setTimeout(() => {
        //   let el = document.getElementById('videoPlayer') as HTMLVideoElement
        //   el.play()
        // }, 1500);
        break;
      case 2:

        break;
      case 3:
        break;
      case 4:
        break;

      default:
        // let el = document.getElementById('videoPlayer') as HTMLVideoElement
        // if(el){
        //   el.pause();
        // }
        break;
    }
  }
}
