<div class="align-center">
  <div class="container-grid">
    <div class="row">
      <div class="col-lg-4 grid-end">
        <div class="texto" [innerHTML]="'INTRO-SESSION.INTRO-PAGE5.CONTEUDO-TEXTO' | translate">
        </div>

        <div class="titulo" [style.color]="'#241a77'" [innerHTML]="'INTRO-SESSION.INTRO-PAGE5.CONTEUDO' | translate"></div>
      </div>
      <div class="col-lg-4 grid-end">
        <div class="texto" [innerHTML]="'INTRO-SESSION.INTRO-PAGE5.BRANDING-TEXTO' | translate">
        </div>
        <div class="titulo" [style.color]="'#009640'" [innerHTML]="'INTRO-SESSION.INTRO-PAGE5.BRANDING' | translate"></div>
      </div>
      <div class="col-lg-4 grid-end">
        <div class="texto" [innerHTML]="'INTRO-SESSION.INTRO-PAGE5.ARTE-TEXTO' | translate">
        </div>
        <div class="titulo" [style.color]="'#e30613'" [innerHTML]="'INTRO-SESSION.INTRO-PAGE5.ARTE' | translate"></div>
      </div>
    </div>
    <div class="image-line" [appSwiperParallax]="swiperConfigService.paralaxNiveis.frente"></div>
    <div class="image-line-divider"></div>
    <div class="row">
      <div class="col-lg-4">
        <div class="titulo" [style.color]="'#831f82'" [innerHTML]="'INTRO-SESSION.INTRO-PAGE5.INOVACAO' | translate"></div>
        <div class="texto" [innerHTML]="'INTRO-SESSION.INTRO-PAGE5.INOVACAO-TEXTO' | translate">
        </div>
      </div>
      <div class="col-lg-4">
        <div class="titulo" [style.color]="'#009fe3'" [innerHTML]="'INTRO-SESSION.INTRO-PAGE5.DESIGN' | translate"></div>
        <div class="texto" [innerHTML]="'INTRO-SESSION.INTRO-PAGE5.DESIGN-TEXTO' | translate">
        </div>
      </div>
      <div class="col-lg-4">
        <div class="titulo" [style.color]="'#f07d00'" [innerHTML]="'INTRO-SESSION.INTRO-PAGE5.TECNOLOGIA' | translate"></div>
        <div class="texto" [innerHTML]="'INTRO-SESSION.INTRO-PAGE5.TECNOLOGIA-TEXTO' | translate">
        </div>
      </div>
    </div>
  </div>
  <div class="image-colchete-esquerdo" [appSwiperParallax]="swiperConfigService.paralaxNiveis.atras"></div>
  <div class="image-colchete-direito" [appSwiperParallax]="swiperConfigService.paralaxNiveis.atras"></div>
</div>
