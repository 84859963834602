import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { SwiperConfigurationsService } from "src/app/services/swiper-configurations.service";
import { SwiperComponent } from "ngx-swiper-wrapper";

@Component({
  selector: "intro-session-page",
  templateUrl: "./intro-session-page.component.html",
  styleUrls: ["./intro-session-page.component.scss"]
})
export class IntroSessionPageComponent implements OnInit, AfterViewInit {
  @ViewChild("swContainer", { read: false, static: true })
  swContainer: SwiperComponent;
  currentIndex = 0;

  constructor(public swiperConfigService: SwiperConfigurationsService) {
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.swiperConfigService.swiperIntro = this.swContainer;
  }

  onSlideIndexChange(data) {
    switch (data) {
      case 1:
        
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        break;

      default:
        break;
    }
  }
}
