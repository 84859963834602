import { Component, OnInit } from '@angular/core';
import { SwiperConfigurationsService } from 'src/app/services/swiper-configurations.service';

@Component({
  selector: 'app-intro-page4',
  templateUrl: './intro-page4.component.html',
  styleUrls: ['./intro-page4.component.scss']
})
export class IntroPage4Component implements OnInit {

  constructor(public swiperConfigService: SwiperConfigurationsService) { }

  ngOnInit() {
  }

}
