import { Injectable } from "@angular/core";
import { SwiperConfig, SwiperComponent } from "ngx-swiper-wrapper";

@Injectable({
  providedIn: "root"
})
export class SwiperConfigurationsService {
  constructor() { }

  private distanciaEntreSlides = screen.width;

  public swiperPrincipal: SwiperComponent;
  public swiperIntro: SwiperComponent;

  public readonly swiperPrincipalConfig = {
    allowSlideNext: true,
    allowSlidePrev: true,
    direction: "vertical",
    nested: true,
    parallax: false,
    speed: 1000,
    threshold: 30,
    hashNavigation: {
      watchState: true,
    }
  } as SwiperConfig;

  public readonly swiperFilhoConfig = {
    allowSlideNext: true,
    allowSlidePrev: true,
    direction: "horizontal",
    parallax: true,
    speed: 2000,
    spaceBetween: this.distanciaEntreSlides,
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    mousewheel: true,
    threshold: 30,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  } as SwiperConfig;

  public readonly swiperBrandsConfig = {
    allowSlideNext: true,
    allowSlidePrev: true,
    direction: "horizontal",
    speed: 500,
    slidesPerView: 2,
    slidesPerColumn: 3,
    threshold: 30,
    autoplay: true,
    spaceBetween: 20,
    height: 200
  } as SwiperConfig;

  public readonly swiperPortfolioConfig = {
    allowSlideNext: true,
    allowSlidePrev: true,
    direction: "horizontal",
    speed: 500,
    threshold: 30,
    pagination: true,
    nested: true,
    slidesPerView: 1,
    spaceBetween: 10,
    centeredSlides: true,
  } as SwiperConfig;

  public readonly swiperPortfolioMobileConfig = {
    allowSlideNext: true,
    allowSlidePrev: true,
    direction: "horizontal",
    speed: 500,
    autoplay: true,
    pagination: true,
    nested: true,
    slidesPerView: 2,
    slidesPerColumn: 3,
    height: 200,
    spaceBetween: 10
  } as SwiperConfig;

  public readonly paralaxNiveis = {
    muitoatras: screen.width * 2,
    atras: screen.width,
    centro: 0,
    frente: screen.width * (-1)
  };
}
