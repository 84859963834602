import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
  selector: "[appSwiperParallax]"
})
export class SwiperParallaxDirective implements OnInit {
  @Input("appSwiperParallax") swiperParallax;

  readonly element: HTMLElement;

  constructor(el: ElementRef) {
    this.element = el.nativeElement as HTMLElement;
  }

  ngOnInit(): void {
    this.element.setAttribute("data-swiper-parallax", this.swiperParallax);
  }
}
