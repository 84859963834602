<div
  class="texto-container texto-esquerda"
  [appSwiperParallax]="swiperConfigService.paralaxNiveis.frente"
  [innerHTML]="'INTRO-SESSION.INTRO-PAGE2.TEXTO-ESQUERDA' | translate"
>
  
</div>
<div
  class="texto-container texto-direita"
  [appSwiperParallax]="swiperConfigService.paralaxNiveis.frente"
  [innerHTML]="'INTRO-SESSION.INTRO-PAGE2.TEXTO-DIREITA' | translate"
>
  
</div>


<div class="imagem-container">
  <div
  class="imagem-celular"
  [appSwiperParallax]="swiperConfigService.paralaxNiveis.centro"
></div>

<div
  class="imagem-globo"
  [appSwiperParallax]="swiperConfigService.paralaxNiveis.atras"
></div>
</div>

<div
  class="imagem-conector"
  [appSwiperParallax]="swiperConfigService.paralaxNiveis.frente"
></div>

<div
  class="imagem-line1"
  [appSwiperParallax]="swiperConfigService.paralaxNiveis.atras"
></div>


<div
  class="imagem-line2"
  [appSwiperParallax]="swiperConfigService.paralaxNiveis.atras"
></div>

