import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { SwiperConfigurationsService } from 'src/app/services/swiper-configurations.service';
import { CasesPage2Component } from './cases-page2/cases-page2.component';
import { PortfolioModel } from 'src/app/models/portfolio.model';

@Component({
  selector: 'cases-session-page',
  templateUrl: './cases-session-page.component.html',
  styleUrls: ['./cases-session-page.component.scss']
})
export class CasesSessionPageComponent implements OnInit {
  constructor(public swiperConfigService: SwiperConfigurationsService) { }

  @ViewChild('casesPage2', { read: false, static: true }) casesPage2: CasesPage2Component;

  @Input() openPortfolioEventInput;
  @Output() openPortfolioEventOutput = new EventEmitter<PortfolioModel>();

  ngOnInit(): void { }

  onSlideIndexChange(data) {
    switch (data) {
      case 1:
        this.casesPage2.getPortfolio();
        console.log('port');
        this.setArrowsEnabled(false);
        break;
      case 2:

        break;
      case 3:
        break;
      case 4:
        break;

      default:
        this.setArrowsEnabled(true);
        break;
    }
  }

  openPortfolioEvent(event) {
    this.openPortfolioEventOutput.emit(event);
  }

  setArrowsEnabled(enabled) {
    if(!enabled){
      let el = document.querySelector('#cases-swiper .swiper-button-next') as HTMLElement;
      el.style.opacity = '0';
    }
    else{
      let el = document.querySelector('#cases-swiper .swiper-button-next') as HTMLElement;
      el.style.opacity = '1';
    }
  }
}
