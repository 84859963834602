<div class="container-page">
  <div
    class="texto"
    [appSwiperParallax]="swiperConfigService.paralaxNiveis.frente"
  ><br /><br /><br />
    <h1 style="font-weight: bold;padding-left: 85px;">llacuna [...]</h1>
    <br />
    <p [innerHTML]="'LLACUNA-SESSION.LLACUNA-PAGE1.P1' | translate" style="width: 90%;"></p>

    <p [innerHTML]="'LLACUNA-SESSION.LLACUNA-PAGE1.P2' | translate"  style="width: 90%;">
    </p>
    <p style="text-align: center;">
      <iframe width="400" height="225" src="https://www.youtube.com/embed/MO22ngvfeFw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </p>
  </div>
</div>

<div
  class="rotate-container"
  [appSwiperParallax]="swiperConfigService.paralaxNiveis.atras"
>
  <div class="imagem-orbita"></div>
</div>
<br /><br />
<div class="imagem-thecreativecontent"></div>
