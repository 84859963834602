import { Component, OnInit } from '@angular/core';
import { SwiperConfigurationsService } from 'src/app/services/swiper-configurations.service';

@Component({
  selector: 'app-llacuna-page1',
  templateUrl: './llacuna-page1.component.html',
  styleUrls: ['./llacuna-page1.component.scss']
})
export class LlacunaPage1Component implements OnInit {

  constructor(public swiperConfigService: SwiperConfigurationsService) { }

  ngOnInit() {
  }

}
