<div
  class="swiper-container"
  [swiper]="swiperConfigService.swiperFilhoConfig"
  id="swContainer"
  #swContainer
  (indexChange)="onSlideIndexChange($event)"
  [index]="currentIndex"
>
  <div class="swiper-wrapper">
    <div class="swiper-slide">
      <app-intro-page1></app-intro-page1>
    </div>
    <div class="swiper-slide">
      <app-intro-page2></app-intro-page2>
    </div>
    <div class="swiper-slide">
      <app-intro-page3></app-intro-page3>
    </div>
    <div class="swiper-slide">
      <app-intro-page4></app-intro-page4>
    </div>
    <div class="swiper-slide">
      <app-intro-page5></app-intro-page5>
    </div>
  </div>

  <div class="swiper-button-next"></div>
  <div class="swiper-button-prev"></div>
  
</div>
