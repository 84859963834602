import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { PortfolioModel } from 'src/app/models/portfolio.model';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { SwiperConfig, SwiperComponent } from 'ngx-swiper-wrapper';
import { SwiperConfigurationsService } from 'src/app/services/swiper-configurations.service';
import Swiper from 'swiper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-portfolio-panel',
  templateUrl: './portfolio-panel.component.html',
  styleUrls: ['./portfolio-panel.component.scss']
})
export class PortfolioPanelComponent implements OnInit {

  constructor(private portfolioService: PortfolioService, public swiperCfgService: SwiperConfigurationsService, private detector: ChangeDetectorRef, public translateService: TranslateService) { }

  @Input('selectedPortfolio') selectedPortfolio: PortfolioModel;
  @Output('deselectPortfolioEvent') deselectPortfolioEvent = new EventEmitter<any>();

  @ViewChild('swiperImgs', { static: false, read: false }) swiperImgs: SwiperComponent;

  currentAlbum = [];

  ngOnInit() {
    this.getAlbumFromPortfolio();
  }

  getAlbumFromPortfolio() {
    this.portfolioService.getAlbumFromPortfolio(this.selectedPortfolio.albumUrl).subscribe((res: Array<string>) => {
      if (res) {
        if (res.length > 0) {
          let sw = new Swiper('.swiper-container.swiper-portfolio', this.swiperCfg);
          res.forEach((r) => {
            this.currentAlbum.push('https://llacuna.ag/albuns/' + this.selectedPortfolio.albumUrl + '/' + r);
          });
          setTimeout(() => {
            sw.update();
          }, 500);
        }
      }
    }, (err) => {
      console.log(err);
    })
  }

  deselectPortfolio() {
    this.currentAlbum = [];
    this.selectedPortfolio = undefined;
    this.deselectPortfolioEvent.emit();
  }

  assistirVideo(video){
    window.open(video.url, '_blank');
  }

  swiperCfg = {
    allowSlideNext: true,
    allowSlidePrev: true,
    autoplay: true,
    pagination: {
      el: '.swiper-pagination',
    },
  } as SwiperConfig;

}
