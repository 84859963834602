import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscriber, Observable, BehaviorSubject } from 'rxjs';
import { PortfolioModel } from '../models/portfolio.model';

@Injectable()
export class PortfolioService{
    constructor(private httpClient: HttpClient){

    }
    getPortfolio(){
        return this.httpClient.get('https://llacuna.ag/albuns/portfolios-v2.json');
    }

    getAlbumFromPortfolio(albumUrl){
        return this.httpClient.get('https://llacuna.ag/api/getImagens.php?dir=' + albumUrl);
    }
}