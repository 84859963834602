import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-intro-page1',
  templateUrl: './intro-page1.component.html',
  styleUrls: ['./intro-page1.component.scss']
})
export class IntroPage1Component implements OnInit, AfterViewInit{

  // @ViewChild('video', { static: true}) video: ElementRef;
  media;
  
  constructor() { }

  ngOnInit() {
    // this.video.nativeElement.load();
  }

  ngAfterViewInit(): void {
    // this.video.nativeElement.play();
  }

}
