import { Component, OnInit, Injectable, ɵAPP_ROOT } from '@angular/core';
import { BrandModel } from '../models/brand.model';
import { PortfolioModel } from '../models/portfolio.model';

@Injectable({providedIn: 'root'})
export class DataProviderService {
    brandsList = [
        {
            // ABRASCE
            imageUrl: 'assets/Imagens/logos/logo-abrasce.png',
            backgroundPosition: '0 71.270718%',
            backgroundSize: '131.506849%',
            url: 'https://abrasce.com.br/',
            cssClass: 'logo-abrasce',
            imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJIAAACEAQMAAABBOuBLAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABlJREFUeNrtwTEBAAAAwqD1T20Hb6AAAOA3ClAAAX5bq44AAAAASUVORK5CYII='
        },
        {
            // FORD
            imageUrl: 'assets/Imagens/logos/logo-ford.png',
            backgroundPosition: '0 54.266467%',
            backgroundSize: '112.941176%',
            url: 'https://www.ford.com.br',
            cssClass: 'logo-ford',
            imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKoAAAA/AQMAAABKE8ExAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABRJREFUeNpjYBgFo2AUjIJRMKgBAAWpAAH1aScTAAAAAElFTkSuQmCC'
        },
        {
            // ABB 
            imageUrl: 'assets/Imagens/logos/logo-abb.png',
            backgroundPosition: '0 41.635409%',
            backgroundSize: '112.280702%',
            url: 'https://new.abb.com/br',
            cssClass: 'logo-abb',
            imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKsAAABCAQMAAAASbbQQAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABRJREFUeNpjYBgFo2AUjIJRMPwBAAXuAAFXJnHyAAAAAElFTkSuQmCC'
        },
        {
            // SCHAEFFLER
            imageUrl: 'assets/Imagens/logos/logo-schaeffler.png',
            backgroundPosition: '0 61.058738%',
            backgroundSize: '113.609467%',
            url: 'https://www.schaeffler.com.br/pt/index.jsp',
            cssClass: 'logo-schaeffler',
            imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKkAAAAUAQMAAADMT4/HAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAAA5JREFUeNpjYBgFwwUAAAHMAAFFSCHFAAAAAElFTkSuQmCC'
        },
        {
            // HONDA
            imageUrl: 'assets/Imagens/logos/logo-honda.png',
            backgroundPosition: '0 63.280116%',
            backgroundSize: '116.363636%',
            url: 'https://www.honda.com.br/',
            cssClass: 'logo-honda',
            imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKUAAAAVAQMAAAAdL7zsAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAAA5JREFUeNpjYBgFwwcAAAHOAAHRU+SCAAAAAElFTkSuQmCC'
        },
        {
            // CD PROJEKT RED
            imageUrl: 'assets/Imagens/logos/logo-cdprojektred.png',
            backgroundPosition: '0 0%',
            backgroundSize: '100%',
            url: 'https://en.cdprojektred.com/',
            cssClass: 'logo-cdprojektred',
            imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMAAAACWAQMAAACyxRWuAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABtJREFUeNrtwTEBAAAAwqD1T20ND6AAAADgxQAOpgABySbebwAAAABJRU5ErkJggg=='
        },
        {
            // ALLENT
            imageUrl: 'assets/Imagens/logos/logo-allent.png',
            backgroundPosition: '0 48.22695%',
            backgroundSize: '112.941176%',
            url: 'https://allent.com.br/',
            cssClass: 'logo-allent',
            imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKoAAAAoAQMAAABUwPMSAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABFJREFUeNpjYBgFo2AUDDYAAAOYAAF4xFrFAAAAAElFTkSuQmCC'
        },
        {
            // DASA
            imageUrl: 'assets/Imagens/logos/logo-dasa.png',
            backgroundPosition: '0 48.22695%',
            backgroundSize: '112.941176%',
            url: 'https://www.dasa.com.br/',
            cssClass: 'logo-dasa',
            imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKoAAAAoAQMAAABUwPMSAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABFJREFUeNpjYBgFo2AUDDYAAAOYAAF4xFrFAAAAAElFTkSuQmCC'
        },
        {
            // BRASKEM
            imageUrl: 'assets/Imagens/logos/logo-braskem.png',
            backgroundPosition: '0 48.22695%',
            backgroundSize: '112.941176%',
            url: 'https://www.braskem.com.br/',
            cssClass: 'logo-braskem',
            imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKoAAAAoAQMAAABUwPMSAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABFJREFUeNpjYBgFo2AUDDYAAAOYAAF4xFrFAAAAAElFTkSuQmCC'
        },
        {
            // PARQUETUR
            imageUrl: 'assets/Imagens/logos/logo-parquetur.png',
            backgroundPosition: '0 48.22695%',
            backgroundSize: '112.941176%',
            url: 'https://parquetur.com.br/',
            cssClass: 'logo-parketur',
            imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKoAAAAoAQMAAABUwPMSAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABFJREFUeNpjYBgFo2AUDDYAAAOYAAF4xFrFAAAAAElFTkSuQmCC'
        },
        {
            // CAMINHOS DO MAR
            imageUrl: 'assets/Imagens/logos/logo-caminhosdomar.png',
            backgroundPosition: '0 48.22695%',
            backgroundSize: '112.941176%',
            url: 'https://caminhosdomar.com.br/',
            cssClass: 'logo-caminhosdomarl',
            imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKoAAAAoAQMAAABUwPMSAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABFJREFUeNpjYBgFo2AUDDYAAAOYAAF4xFrFAAAAAElFTkSuQmCC'
        },
        {
            // CONTINENTAL
            imageUrl: 'assets/Imagens/logos/logo-continental.png',
            backgroundPosition: '0 48.22695%',
            backgroundSize: '112.941176%',
            url: 'https://www.continental.com/en/',
            cssClass: 'logo-continental',
            imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKoAAAAoAQMAAABUwPMSAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABFJREFUeNpjYBgFo2AUDDYAAAOYAAF4xFrFAAAAAElFTkSuQmCC'
        },
        
        // {
        //     // FACEBOOK
        //     imageUrl: 'assets/Imagens/logos/logo-sprite.png',
        //     backgroundPosition: '0 44.973545%',
        //     backgroundSize: '112.280702%',
        //     url: '',
        //     cssClass: 'logo-facebook',
        //     imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKsAAAAiAQMAAAAambtKAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABBJREFUeNpjYBgFo2AUQAAAAw4AAUmD4OsAAAAASUVORK5CYII='
        // },
        // {
        //     // THAIS RIBEIRO
        //     imageUrl: 'assets/Imagens/logos/logo-sprite.png',
        //     backgroundPosition: '0 84.28246%',
        //     backgroundSize: '154.83871%',
        //     url: '',
        //     cssClass: 'logo-thaisribeiro',
        //     imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHwAAABSAQMAAACsdiK7AAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABRJREFUeNpjYBgFo2AUjIJRQB8AAAVyAAH2a87ZAAAAAElFTkSuQmCC'
        // },
        
        // {
        //     // LIGA 
        //     imageUrl: 'assets/Imagens/logos/logo-sprite.png',
        //     backgroundPosition: '0 35.54717%',
        //     backgroundSize: '111.627907%',
        //     url: '',
        //     cssClass: 'logo-liga',
        //     imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKwAAABKAQMAAAAc4i0EAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABZJREFUeNpjYBgFo2AUjIJRMAoGKwAABqYAASuwlYEAAAAASUVORK5CYII='
        // },
        // {
        //     // BLIZZARD
        //     imageUrl: 'assets/Imagens/logos/logo-sprite.png',
        //     backgroundPosition: '0 17.647059%',
        //     backgroundSize: '110.982659%',
        //     url: '',
        //     cssClass: 'logo-blizzard',
        //     imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAK0AAABaAQMAAADw9kShAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABhJREFUeNpjYBgFo2AUjIJRMApGwSgAAQAIFgABsa11ugAAAABJRU5ErkJggg=='
        // },
        // {
        //     // CONTINENTAL
        //     imageUrl: 'assets/Imagens/logos/logo-sprite.png',
        //     backgroundPosition: '0 48.22695%',
        //     backgroundSize: '112.941176%',
        //     url: '',
        //     cssClass: 'logo-continental',
        //     imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKoAAAAoAQMAAABUwPMSAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABFJREFUeNpjYBgFo2AUDDYAAAOYAAF4xFrFAAAAAElFTkSuQmCC'
        // },
        // {
        //     // MENTALGUILD
        //     imageUrl: 'assets/Imagens/logos/logo-sprite.png',
        //     backgroundPosition: '0 57.054674%',
        //     backgroundSize: '112.941176%',
        //     url: '',
        //     cssClass: 'logo-mentalguild',
        //     imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKoAAAAiAQMAAAD1W9B0AAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABBJREFUeNpjYBgFo2AUQAAAAw4AAUmD4OsAAAAASUVORK5CYII='
        // },
        // {
        //     // UVA FRANCIS
        //     imageUrl: 'assets/Imagens/logos/logo-sprite.png',
        //     backgroundPosition: '0 76.72956%',
        //     backgroundSize: '148.837209%',
        //     url: '',
        //     cssClass: 'logo-uvafrancis',
        //     imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIEAAAA3AQMAAAAR/bt7AAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABFJREFUeNpjYBgFo2AUDE8AAAPeAAF1Iz5PAAAAAElFTkSuQmCC'
        // },
        // {
        //     // SAO MARCELO
        //     imageUrl: 'assets/Imagens/logos/logo-sprite.png',
        //     backgroundPosition: '0 93.479909%',
        //     backgroundSize: '188.235294%',
        //     url: '',
        //     cssClass: 'logo-saomarcelo',
        //     imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGYAAABQAQMAAADRMpKRAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABJJREFUeNpjYBgFo2AUjALaAAAEYAABx+jyEAAAAABJRU5ErkJggg=='
        // },
        // {
        //     // VOCE
        //     imageUrl: 'assets/Imagens/logos/logo-sprite.png',
        //     backgroundPosition: '0 11.958146%',
        //     backgroundSize: '104.918033%',
        //     url: '',
        //     cssClass: 'logo-voce',
        //     imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALcAAAA9AQMAAADVi2piAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABRJREFUeNpjYBgFo2AUjIJRMJQAAAW4AAFMEvLXAAAAAElFTkSuQmCC'
        // },
        // {
        //     // FRUTAYA
        //     imageUrl: 'assets/Imagens/logos/logo-sprite.png',
        //     backgroundPosition: '0 86.399303%',
        //     backgroundSize: '177.777778%',
        //     url: '',
        //     cssClass: 'logo-frutaya',
        //     imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGwAAAAVAQMAAACZhZmHAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAAA1JREFUeNpjYBgFZAIAATsAAYYWsqUAAAAASUVORK5CYII='
        // },
        // {
        //     // VERO FRUTTO
        //     imageUrl: 'assets/Imagens/logos/logo-sprite.png',
        //     backgroundPosition: '0 100%',
        //     backgroundSize: '228.571429%',
        //     url: '',
        //     cssClass: 'logo-verofrutto',
        //     imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAABMAQMAAADJIAJ0AAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABFJREFUeNpjYBgFo2AUDBYAAAOQAAG5ajLhAAAAAElFTkSuQmCC'
        // }
        // {
        //     // DOCE UVA
        //     imageUrl: 'assets/Imagens/logos/logo-sprite.png',
        //     backgroundPosition: '0 26.08353%',
        //     backgroundSize: '111.627907%',
        //     url: '',
        //     cssClass: 'logo-doceuva',
        //     imageBase64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKwAAACCAQMAAADhWbHdAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAABpJREFUeNrtwTEBAAAAwqD1T20ND6AAAIBPAwuuAAGivjx+AAAAAElFTkSuQmCC'
        // },
    ] as Array<BrandModel>;

    portfolioList = [] as Array<PortfolioModel>
}