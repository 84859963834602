<div class="container logos-grid if-desktop">
    <div class="row logos-align">
        <div class="col-sm-3 logo mb-5 mt-5" *ngFor="let item of dataProviderService.brandsList">
            <div style="max-width: 100%; background-size: 100%;">
                <a [href]="item.url" target="_blank"><img [src]="item.imageUrl" [class]="item.cssClass" width="240px"></a>
            </div>
        </div>
    </div>
</div>

<div class="max-height-content if-mobile">
    <div class="if-mobile" style="width: 75%; margin: auto;">
        <div class="swiper-container" [swiper]="swiperConfigurationService.swiperBrandsConfig">
            <div class="swiper-wrapper">
                <div class="swiper-slide height-slide" *ngFor="let item of dataProviderService.brandsList">
                    <div class="center-content">
                        <div style="max-width: 100%; background-size: 100%;">
                            <a [href]="item.url" target="_blank"><img [src]="item.imageUrl" [class]="item.cssClass"></a>
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
</div>