<div
  [hidden]="isMobile"
  class="llacuna-desktop-menu-container"
  (click)="closeMenu($event)"
>
  <div class="llacuna-desktop-menu-x">
    <div class="llacuna-colormenu-home">
      <div class="llacuna-desktop-menu-wrapper">
        <!-- HOME -->
        <div class="llacuna-desktop-grid">
          <div
            class="llacuna-desktop-menu-btn"
            [ngClass]="{ btnAtivo: item.ativo }"
            *ngFor="let item of menuItens"
            (click)="onMenuTap(item.id)"
          >
            {{ item.texto }}
          </div>
        </div>
        <div class="llacuna-desktop-menu-contato-home">
          <span appCopyOnClick>+55 11 3368.1572</span><br />
          <span appCopyOnClick
            >Rua Baronesa de Bela Vista, 411 - Conj 519<br />Campo Belo • São
            Paulo - SP</span
          >
          <br />
          <span appCopyOnClick>contato@llacuna.ag</span>
        </div>
      </div>
    </div>
    <div class="llacuna-colormenu-llde">
      <div class="llacuna-desktop-menu-wrapper">
        <!-- PROJETOS -->
        <div class="llacuna-desktop-grid-row-projetos">
          <div
            class="llacuna-desktop-menu-btn-row"
            [ngClass]="{ btnAtivo: item.ativo }"
            *ngFor="let item of menuItens"
            (click)="onMenuTap(item.id)"
          >
            {{ item.texto }}
          </div>
        </div>
        <div class="llacuna-desktop-menu-contato-projetos">
          <span appCopyOnClick>+55 11 3368.1572</span>
          
          <br />
          <span appCopyOnClick>contato@llacuna.ag</span>
        </div>
      </div>
    </div>
    <div class="llacuna-colormenu-projetos">
      <div class="llacuna-desktop-menu-wrapper">
        <!-- LLDE -->
        <div class="llacuna-desktop-grid-row-llde">
          <div
            class="llacuna-desktop-menu-btn-row"
            [ngClass]="{ btnAtivo: item.ativo }"
            *ngFor="let item of menuItens"
            (click)="onMenuTap(item.id)"
          >
            {{ item.texto }}
          </div>
        </div>
        <div class="llacuna-desktop-menu-contato-llde">
          <span appCopyOnClick>+55 11 3368.1572</span>
         
          <br />
          <span appCopyOnClick>contato@llacuna.ag</span>
        </div>
      </div>
    </div>
    <div class="llacuna-colormenu-contato">
      <div class="llacuna-desktop-menu-wrapper">
        <!-- CONTATO -->
        <div class="llacuna-desktop-grid-row-contato">
          <div
            class="llacuna-desktop-menu-btn-row"
            [ngClass]="{ btnAtivo: item.ativo }"
            *ngFor="let item of menuItens"
            (click)="onMenuTap(item.id)"
          >
            {{ item.texto }}
          </div>
        </div>
        <div class="llacuna-desktop-menu-contato-contato">
          <span appCopyOnClick>+55 11 3368.1572</span>
          
          <br />
          <span appCopyOnClick>contato@llacuna.ag</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div [hidden]="isMobile" class="llacuna-desktop-menu-close-container"></div>

<div
  class="llacuna-mobile-menu-toggleButton"
  (click)="openMenu()"
  [hidden]="isMenuOpen === true"
></div>

<div class="llacuna-menu-language-selector" [hidden]="isMenuOpen === true">
  <div [class]="'btn-language-selector ' + (activeLanguage === 'pt' ? 'active' : '')" (click)="changeLanguage('pt')"><div>PT</div></div>
  <div [class]="'btn-language-selector ' + (activeLanguage === 'en' ? 'active' : '')" (click)="changeLanguage('en')"><div>EN</div></div>
</div>
<div
  [hidden]="!isMobile"
  class="llacuna-mobile-menu-container"
  (click)="closeMobileMenu()"
>
  <div class="llacuna-mobile-menu-wrapper">
    <div
      class="llacuna-mobile-menu-button"
      *ngFor="let item of menuItens"
      (click)="onMenuTap(item.id)"
    >
      {{ item.texto }}
    </div>
  </div>
  <div class="llacuna-mobile-menu-contato-home">
    +55 11
    <span appCopyOnClick>3368.1572</span> •
    <!--<span appCopyOnClick>3368.1729</span>-->
    <br />
    <br />
    <span appCopyOnClick
      >Rua baronesa da Bela vista, 411 – Cj 519. <br/>Campo Belo • São
      Paulo – SP</span
    >
    <br />
    <br />
    <span appCopyOnClick>contato@llacuna.ag</span>
  </div>
</div>
