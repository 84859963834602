<div
  class="texto-container texto-esquerda2 centro line-height"
  [appSwiperParallax]="swiperConfigService.paralaxNiveis.frente"
  [innerHTML]="'INTRO-SESSION.INTRO-PAGE4.TEXTO-ESQUERDA2' | translate"
  
></div>
<div
  class="texto-container texto-direita baixo line-height"
  [appSwiperParallax]="swiperConfigService.paralaxNiveis.frente"
  [innerHTML]="'INTRO-SESSION.INTRO-PAGE4.TEXTO-ESQUERDA' | translate"
>
  
</div>
<div
  class="texto-container texto-esquerda cima text-right line-height"
  [appSwiperParallax]="swiperConfigService.paralaxNiveis.frente"
  [innerHTML]="'INTRO-SESSION.INTRO-PAGE4.TEXTO-DIREITA' | translate"
>
  
</div>


<div class="imagem-dna" [appSwiperParallax]="swiperConfigService.paralaxNiveis.frente"></div>

<!-- Segundo Plano -->
<div class="imagem-brain"></div>
<div class="imagem-line1"></div>
<div class="imagem-line2"></div>

<!-- Terceiro Plano -->
<div class="imagem-connection" [appSwiperParallax]="swiperConfigService.paralaxNiveis.atras"></div>
<div class="imagem-dna2" [appSwiperParallax]="swiperConfigService.paralaxNiveis.atras"></div>
<div class="imagem-dna3" [appSwiperParallax]="swiperConfigService.paralaxNiveis.atras"></div>
<div class="imagem-line3" [appSwiperParallax]="swiperConfigService.paralaxNiveis.atras"></div>
<div class="imagem-line4" [appSwiperParallax]="swiperConfigService.paralaxNiveis.atras"></div>