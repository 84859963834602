<div class="map-wrapper">
  <div class="map-container">
    <!-- Alterado por MKT Studio - escondido os 2 componentes como solicitado pelo cliente -->
    <!--<div class="maps-component" id="mapsContato"></div>
    <div class="imagem-colchete"></div>-->
  </div>
</div>

<div class="contato-container">
  <div class="contato-wrapper">
    <div class="contato-redessociais">
      <div class="row">
        <div class="col-sm-3">
                <a href='https://www.instagram.com/llacuna.ag/' target="_blank"><button class="socialbtn instagram"></button></a>
        </div>
        <div class="col-sm-3"><a href='https://www.facebook.com/llacuna.ag/' target="_blank"><button class="socialbtn facebook"></button></a></div>
        <div class="col-sm-3"><a href='https://www.linkedin.com/company/llacuna/' target="_blank"><button class="socialbtn linkedin"></button></a></div>
        <div class="col-sm-3"><a href='https://www.youtube.com/channel/UCSN5LolOiXAWr62vCdeON-w' target="_blank"><button class="socialbtn youtube"></button></a></div>
      </div>
    </div>

    <div class="contato-dados">
      <p>+55 11 3368.1572</p>
      <!--<p>
        {{ 'CONTATO-SESSION.RUA' | translate}} Baronesa de Bela Vista, 411 - Conj 519<br> Campo Belo • São Paulo - SP
      </p>-->
      <p>contato@llacuna.ag</p>
      <br />
      <p>Copyright © llacuna - {{ 'CONTATO-SESSION.COPYRIGHT' | translate}}</p>
    </div>
  </div>
</div>
