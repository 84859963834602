import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit
} from "@angular/core";
// import { MessageService } from "../../Services/message.service";
// import { ToolsService } from '../../Services/tools-service.service';

import { TimelineMax } from "gsap";
import { SwiperComponent } from 'ngx-swiper-wrapper';
import { SwiperConfigurationsService } from 'src/app/services/swiper-configurations.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "llacuna-desktop-menu",
  templateUrl: "./llacuna-desktop-menu.component.html",
  styleUrls: ["./llacuna-desktop-menu.component.scss"]
})
export class LlacunaDesktopMenuComponent implements OnInit, AfterViewInit {
  constructor(private swiperService: SwiperConfigurationsService, private translateService: TranslateService) {
    this.activeLanguage = translateService.getBrowserLang();
  }

  public swiperPrincipal;

  activeLanguage;

  menuItens = [
    { id: 0, texto: "Home", ativo: true },
    { id: 1, texto: "llacuna [...]", ativo: false },
    { id: 2, texto: "Clientes", ativo: false},
    { id: 3, texto: this.activeLanguage === 'en' ? "Contact" : "Contato", ativo: false }
  ];

  // isMobile = ToolsService.IsMobile();
  isMenuOpen = false;
  isMobile = false;

  ngOnInit() {
    this.changeLanguage(this.activeLanguage)
  }

  ngAfterViewInit(): void {

  }

  changeLanguage(lang){
    this.translateService.use(lang);
    this.activeLanguage = lang;
    this.menuItens[3].texto = this.activeLanguage === 'en' ? "Contact" : "Contato";
  }

  updateActive(index) {
    this.menuItens.forEach(item => {
      if (item.id === index) {
        item.ativo = true;
      } else {
        item.ativo = false;
      }
    });
  }

  onMenuTap(index) {
    if (this.isMobile) {
      this.closeMobileMenu();
    } else {
      let tl = new TimelineMax();
      tl.to(
        ".llacuna-desktop-menu-x",
        1,
        {
          x: -screen.width,
          onComplete: () => {
            let menu = document.getElementsByClassName(
              "llacuna-desktop-menu-container"
            )[0] as HTMLElement;
            menu.style.pointerEvents = "none";
            menu.style.touchAction = "none";
          }
        },
        1
      );
      this.toggleCloseContainer(false);
      this.updateActive(index);
    }
    this.swiperService.swiperPrincipal.directiveRef.swiper().slideTo(index)
  }

  openMenu() {
    if (this.isMobile) {
      let tl = new TimelineMax();
      tl.to(".llacuna-mobile-menu-container", 1, {
        x: 0,
        onComplete: () => {
          let menu = document.getElementsByClassName(
            "llacuna-desktop-menu-container"
          )[0] as HTMLElement;
          menu.style.pointerEvents = "all";
          menu.style.touchAction = "auto";
        }
      });
      this.isMenuOpen = true;
    } else {
      let tl = new TimelineMax();
      tl.to(
        ".llacuna-desktop-menu-x",
        1,
        {
          x: 0,
          onComplete: () => {
            let menu = document.getElementsByClassName(
              "llacuna-desktop-menu-container"
            )[0] as HTMLElement;
            menu.style.pointerEvents = "all";
            menu.style.touchAction = "auto";
          }
        },
        0
      );
      let menu = document.getElementsByClassName(
        "llacuna-desktop-menu-container"
      )[0] as HTMLElement;
      menu.style.display = "inherit";
      this.isMenuOpen = true;
      this.toggleCloseContainer(true);
    }
  }

  closeMenu(evt: MouseEvent) {
    let canClose = true;
    this.isMenuOpen = false;
    console.log(evt);
    if (evt.target) {
      this.isMenuOpen = false;
      const target = evt.target as HTMLElement;
      if (
        target.classList.contains("llacuna-desktop-menu-btn") ||
        target.classList.contains("llacuna-desktop-menu-btn-row") ||
        target.tagName.toLowerCase() === "span"
      ) {
        canClose = false;
      }
    }

    if (canClose) {
      this.isMenuOpen = false;
      this.toggleCloseContainer(false);
      let tl = new TimelineMax();
      tl.to(
        ".llacuna-desktop-menu-x",
        1,
        {
          x: -screen.width,
          onComplete: () => {
            let menu = document.getElementsByClassName(
              "llacuna-desktop-menu-container"
            )[0] as HTMLElement;
            menu.style.display = "none";
          }
        },
        0
      );
    }
  }

  closeMobileMenu() {
    this.isMenuOpen = false;
    let tl = new TimelineMax();
    tl.to(".llacuna-mobile-menu-container", 1, { x: -screen.width });
  }

  registerEvents(swiperPrincipal: SwiperComponent){
    if (!this.isMobile) {
      let that = this;
      setTimeout(function() {
        let menuContainer = document.getElementsByClassName(
          "llacuna-desktop-menu-container"
        )[0] as HTMLElement;
        swiperPrincipal.S_SETTRANSLATE.subscribe(event => {
          menuContainer.style.transform = "translateY(" + event[0] + "px)";
        });
        swiperPrincipal.S_TOUCHSTART.subscribe(event => {
          menuContainer.style.transition = "";
        });
        swiperPrincipal.S_TOUCHEND.subscribe(event => {
          menuContainer.style.transition = "transform 1s";
        });

        let closeCont = document.getElementsByClassName(
          "llacuna-desktop-menu-close-container"
        )[0] as HTMLElement;
        let onClickEvt = function() {
          if (that.isMenuOpen) {
            that.isMenuOpen = false;
            that.toggleCloseContainer(false);
            let tl = new TimelineMax();
            tl.to(
              ".llacuna-desktop-menu-x",
              1,
              {
                x: -screen.width,
                onComplete: () => {
                  let menu = document.getElementsByClassName(
                    "llacuna-desktop-menu-container"
                  )[0] as HTMLElement;
                  menu.style.pointerEvents = "none";
                  menu.style.touchAction = "none";
                }
              },
              0
            );
          }
        };
        closeCont.addEventListener("click", onClickEvt);

        let wrapperContainerArray = document.getElementsByClassName(
          "llacuna-desktop-menu-wrapper"
        );
        for (var i = 0; i < wrapperContainerArray.length; i++) {
          // wrapperContainerArray[i].addEventListener("pointerleave", event => {
          //   let tl = new TimelineMax();
          //   tl.to(".llacuna-desktop-menu-x", 1, {
          //     x: -screen.width / 2, onComplete: () => {
          //       let menu = document.getElementsByClassName("llacuna-desktop-menu-container")[0] as HTMLElement;
          //       menu.style.display = "none";
          //     }
          //   }, 0);
          // });
        }
      }, 300);
    }
  }

  private toggleCloseContainer(visible) {
    let closeCont = document.getElementsByClassName(
      "llacuna-desktop-menu-close-container"
    )[0] as HTMLElement;
    closeCont.style.display = visible ? "block" : "none";
  }
}
